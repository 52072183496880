.error-cell {
  background: #FFEBEE;
  color: #D32F2F;
  font-weight: bold;
}

.error-cell:after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  content: '';
  background: #e95003;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.current-error-cell {
  position: relative;
}

.current-error-cell:after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  content: '';
  background: #ffffff;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.editable-cell {
  position: absolute;
}

.editable-cell:after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  content: '';
  background: #dddddd;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.current-updated-cell {
  position: absolute;
}

.current-updated-cell:after {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  content: '';
  background: #ff0000;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
}

.custom-tooltip {
  background-color: #f0f0f0;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.error-tooltip {
  background-color: #f8d7da; 
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.icon-warning {
  margin-right: 5px;
  color: #f00;
}

.ag-theme-balham .number-cell {
  text-align: right; 
}
	
.parent-header .ag-header-cell-label {
  font-weight: bold;
}

.ag-theme-balham .checkbox-input {
  text-align: center; 
}
