// @import 'ag-grid-community/styles/ag-grid.css';
// @import 'ag-grid-community/styles/ag-theme-quartz.css';

// .ag-theme-quartz {
//   @include ag-theme-quartz(( // use theme parameters where possible

//       cell-horizontal-border: solid ag-derived(secondary-border-color),
//     ));

//   .ag-header-cell {
//     border-right: 1px solid ag-param(secondary-border-color);
//   }
// }
.ag-theme-quartz {
  --ag-cell-horizontal-border: var(--ag-row-border-width) var(--ag-row-border-style) var(--ag-row-border-color);
  /* Add left and right borders to each cell and use row border's properties  */
  --ag-header-column-separator-display: block;

  --ag-font-size: 15px;

  .ag-header-cell,
  .ag-header-group-cell {
    font-weight: bold;
  }
}

.negative-value {
  color: red;
}

.stock-days-warning {
  color: red;
}

.todays-order-cell {
  background: #fff2d1;
}

.future-arrival-exists {
  background: #add8e6;
}

.number-cell {
  text-align: right;
}

.editable-cell {
  &:after {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    content: '';
    //--ag-cell-horizontal-border
    background: #dddddd;
    clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
  }
}
.updated-cell {
  background: #eef7ed;
  color: #55aa4c;
  font-weight: bold;
}

.current-updated-cell {
  &:after {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    content: '';
    //--ag-cell-horizontal-border
    background: #ff0000;
    clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
  }
}

.today-cell {
  background-color: rgba(245, 130, 32, 0.2) !important;
}

.value-header-rec {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.value-header-today {
  background-color: rgba(245, 130, 32, 0.2) !important;
}
.value-header-expected {
  background-color: rgba(0, 0, 255, 0.1) !important;
}

.stock-cell-input {
  outline: none !important;
  border: none;
  background: transparent;
}

.rating-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.rating-cell {
  margin-top: 5px;
}

.loading-box {
  padding: 2rem;
}

.hidden-grid {
  display: none;
}

.ag-header-cell {
  &.sunday {
    color: red;
  }
  &.newitem_start_header {
    background-color: #c71585 !important;
  }
}

.over-stock-value {
  color: red;
}

.over-order-value {
  color: red;
}

.ag-filter-wrapper {
  width: 400px;
}

.ag-set-filter-list,
.ag-filter-no-matches {
  height: calc(var(--ag-list-item-height) * 10);
}

.bold-border-cell {
  border: 1px solid #000; 
  font-weight: bold;
}

.font-style {
  font-weight: bold;
}
.ag-checkbox-cell {
  display: flex;
  justify-content: end;
}
